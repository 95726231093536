<template>
  <div id="app">
    <header>
      <div class="logo-desktop">
        <a href="/" class="logo-link" v-if="isDesktop">
          <img alt="Company Logo" src="./assets/logo.png" class="logo">
        </a>
      </div>
      <nav>
        <button class="menu-toggle" @click="toggleMenu">☰</button>
        <ul :class="{ 'active': isMenuOpen }">
          <li><router-link to="/" @click="toggleMenu">Trang Chính</router-link></li>
          <li><router-link to="/about" @click="toggleMenu">Về Chúng Tôi</router-link></li>
          <li><router-link to="/systems" @click="toggleMenu">Danh sách hệ thống</router-link></li>
          <li><router-link to="/gas-price" @click="toggleMenu">Giá xăng dầu</router-link></li>
          <li><router-link to="/hello-world" @click="toggleMenu">Biểu đồ</router-link></li>
          <li><router-link to="/timeline" @click="toggleMenu">Lịch sử</router-link></li>
        <!-- Phần menu Đăng Nhập / Đăng Xuất -->
          <template v-if="!isAuthenticated">
             <li><router-link to="/dang-nhap" @click="toggleMenu">Đăng Nhập</router-link></li>
          </template>
          <template v-else>
             <li><span>{{ email }}</span></li>
             <li><router-link to="/logout" @click="toggleMenu">Giao Dịch</router-link></li>
             <li><router-link to="/dang-xuat" @click="toggleMenu">User Profile</router-link></li>
          </template>
                    <div class="t-center">
                       <a href="/" v-if="!isDesktop">
                            <img alt="Company Logo" src="./assets/logo.png" class="logo">
                       </a>
                    </div>
          </ul>
        </nav>
    </header>
    <main>
      <div class="content-container">
        <router-view :systems="systems" />
        <button v-if="pwaStore.deferredPrompt" class="install-button" @click="pwaStore.promptInstall">
          Cài Đặt Ứng Dụng
        </button>
      </div>
    </main>
    <footer>
      <p>&copy; 2024 HKOIL. Tất cả các quyền được bảo lưu.</p>
    </footer>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { usePWAStore } from './stores/usePWAStore'; // Import store
import { useAuthStore } from './stores/authStore'; // Import auth store

export default defineComponent({
  name: 'App',
  setup() {
    const pwaStore = usePWAStore(); // Khởi tạo store PWA
    const authStore = useAuthStore(); // Khởi tạo store Auth

    const isAuthenticated = computed(() => authStore.isAuthenticated); // Kiểm tra trạng thái đăng nhập
    const email = computed(() => authStore.email || ''); // Lấy email từ auth store

    return { pwaStore, isAuthenticated, email };
  },
  data() {
    return {
      isMenuOpen: false,
      systems: [
        { id: 1, name: "Hệ thống 1", link: "#", image: "https://cdn.hkoil.org/img/512hklogo.png" },
        { id: 2, name: "Hệ thống 2", link: "#", image: "https://cdn.hkoil.org/img/512hklogo.png" },
        { id: 3, name: "Hệ thống 3", link: "#", image: "https://cdn.hkoil.org/img/512hklogo.png" },
        { id: 4, name: "Hệ thống 4", link: "#", image: "https://cdn.hkoil.org/img/512hklogo.png" },
        { id: 5, name: "Hệ thống 5", link: "#", image: "https://cdn.hkoil.org/img/512hklogo.png" },
        { id: 6, name: "Hệ thống 6", link: "#", image: "https://cdn.hkoil.org/img/512hklogo.png" },
        { id: 7, name: "Hệ thống 7", link: "#", image: "https://cdn.hkoil.org/img/512hklogo.png" },
        { id: 8, name: "Hệ thống 8", link: "#", image: "https://cdn.hkoil.org/img/512hklogo.png" },
        { id: 9, name: "Hệ thống 9", link: "#", image: "https://cdn.hkoil.org/img/512hklogo.png" },
        // ... Thêm nhiều hệ thống khác tại đây
      ]
    };
  },
  computed: {
    isDesktop() {
      return window.innerWidth > 768; // Kiểm tra nếu chiều rộng cửa sổ lớn hơn 768px
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  },
  mounted() {
    document.title = 'TBN';
  }
});
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Đảm bảo chiều cao tối thiểu là 100vh */
}

header {
  background-color: #f3f3f3;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Để menu toggle hoạt động tốt */
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none; /* Remove underline from link */
}

.logo {
  width: 100px; /* Điều chỉnh kích thước logo theo nhu cầu */
  height: auto;
}

nav {
  flex: 1;
  position: relative; /* relative - Để menu toggle hoạt động tốt */
  z-index: 100;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

li {
  margin: 0 5px;
}

a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  text-overflow: ellipsis;
}

a:hover, a.router-link-active {
  background-color: #D9F6D3;
  color: #46cd6e;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 100px;
}

a.router-link-active {
  font-weight: bold;
}

.menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

main {
  flex: 1; /* Chiếm toàn bộ không gian còn lại */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Đưa nội dung lên đầu trang */
  padding: 5px;
}

.content-container {
  width: 95%; /* Nội dung chiếm 90% chiều rộng của trang */
  max-width: 1200px; /* Kích thước tối đa của nội dung */
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

footer {
  background-color: #f3f3f3;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  text-align: center;
  margin-top: auto; /* Đảm bảo footer luôn nằm ở dưới cùng */
}

/* Responsive Styles */
@media (max-width: 768px) {
.logo-desktop {
    display: none; /* Ẩn logo trên màn hình di động */
  }
  .menu-toggle {
    display: block; /* Hiện nút toggle trên màn hình di động */
  }
  nav ul {
    display: none; /* Ẩn menu trên màn hình nhỏ */
    flex-direction: column;
    width: 100%;
    background-color: #f3f3f3;
    position: absolute;
    top: 60px; /* Điều chỉnh theo chiều cao của header */
    left: 0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }

  nav ul.active {
    display: flex; /* Hiển thị menu khi menu toggle được nhấn */
  }

  .menu-toggle {
    display: block; /* Hiển thị nút menu toggle trên màn hình nhỏ */
  }

  li {
    margin: 10px 0;
    text-align: center;
  }

  a {
    padding: 9px;
    font-size: 18px;
  }
}
.t-center{
text-align:center;
}
.install-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #FF6347;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    text-decoration: none;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 15px rgba(255, 99, 71, 0.4);
}

.install-button:hover {
  transform: translateY(-5px) scale(1.05);
  background-color: #FF4500;
  box-shadow: 0 6px 25px rgba(255, 69, 0, 0.6);
}

.install-button:active {
  transform: scale(0.95); /* Co lại khi nhấn */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif; /* Chọn font chữ cho toàn bộ ứng dụng */
  -webkit-font-smoothing: antialiased; /* Cải thiện font cho Safari */
  -moz-osx-font-smoothing: grayscale; /* Cải thiện font cho Firefox */
  /*text-align: center;  Căn giữa nội dung */
  color: #2c3e50; /* Màu chữ chính */
  /* margin-top: 60px;  Khoảng cách trên cùng của ứng dụng */
}
</style>
